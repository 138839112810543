import { Transition } from "@headlessui/react";
import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import HomeIcon from "@heroicons/react/24/outline/HomeIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon";
import CurrencyDollarIcon from "@heroicons/react/24/outline/CurrencyDollarIcon";
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/Cog6ToothIcon";
import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";

import JackrabbitPayLogo from "../assets/logo-jackrabbit-pay-2023-white-final.svg";
import useClickOutside from "../hooks/useClickOutside";
import MenuItem from "./MenuItem";
import HasAccess, { csrRoles } from "./HasAccess";
import { getTransactionsPageDefaultUrl } from "../utils/transaction";
import { getOnboardingPageDefaultUrl } from "../utils/accounts";
import { logPageVisit } from "../utils/appInsightsLogger";
import { useAtom } from "jotai";
import { currentUserAccountAtom } from "../atoms";

type Props = {
  open: boolean;
  toggleMenu: () => void;
};

function Menu({ open, toggleMenu }: Props): JSX.Element {
  const menuRef = useClickOutside(toggleMenu);
  const [currentUserAccount] = useAtom(currentUserAccountAtom);

  const customOnClick = () => {
    toggleMenu();
    logPageVisit("Transactions", currentUserAccount?.username);
  };

  return (
    <div>
      <Transition show={open}>
        <Transition.Child
          ref={menuRef}
          className="fixed top-0 left-0 z-50 h-full w-72 bg-white shadow-[4px_0_12px_-1px_rgba(0,0,0,0.3)]"
          enter="linear duration-300 transition-transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="linear duration-300 transition-transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div>
            <div className="flex h-14 w-full items-center justify-start gap-x-3 bg-primary-700 px-3 py-2">
              <Bars3Icon
                onClick={toggleMenu}
                className="h-9 w-9 cursor-pointer select-none rounded-full p-1 text-white"
              />
              <img
                src={JackrabbitPayLogo}
                alt="Jackrabbit Pay Logo"
                className="mt-1 h-6"
              />
            </div>
            <nav>
              <ul className="mx-3 mt-2 flex flex-col gap-y-1 text-base text-primary-700">
                <MenuItem
                  to="/"
                  icon={<HomeIcon className="h-6 w-6" />}
                  toggleMenu={toggleMenu}
                >
                  Home
                </MenuItem>
                <MenuItem
                  to={getTransactionsPageDefaultUrl()}
                  icon={<CurrencyDollarIcon className="h-6 w-6" />}
                  toggleMenu={customOnClick}
                >
                  Transactions
                </MenuItem>
                <HasAccess allowedRoles={[...csrRoles]}>
                  <MenuItem
                    to={getOnboardingPageDefaultUrl()}
                    icon={<UserGroupIcon className="h-6 w-6" />}
                    toggleMenu={toggleMenu}
                  >
                    Clients
                  </MenuItem>
                </HasAccess>
                <MenuItem
                  to={
                    currentUserAccount?.type === "CSR"
                      ? "/system-settings"
                      : "/customer-settings"
                  }
                  icon={<Cog6ToothIcon className="h-6 w-6" />}
                  toggleMenu={toggleMenu}
                >
                  {currentUserAccount?.type === "CSR" ? "System" : "Settings"}
                </MenuItem>
                <MenuItem
                  to="/reports"
                  icon={<ClipboardDocumentListIcon className="h-6 w-6" />}
                  toggleMenu={toggleMenu}
                >
                  Reports
                </MenuItem>
                <MenuItem
                  to={
                    currentUserAccount?.type === "CSR"
                      ? "/payout"
                      : `/payout/${currentUserAccount?.accountHolderCode}`
                  }
                  icon={<BanknotesIcon className="h-6 w-6" />}
                  toggleMenu={toggleMenu}
                >
                  Payout
                </MenuItem>
              </ul>
            </nav>
          </div>
        </Transition.Child>
        <Transition.Child
          className="fixed inset-0 z-40"
          enter="duration-300 transition-all"
          enterFrom="bg-transparent"
          enterTo="bg-black/30"
          leave="duration-300 transition-all"
          leaveFrom="bg-black/30"
          leaveTo="bg-transparent"
        />
      </Transition>
    </div>
  );
}

export default Menu;
