import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import useUpdateInterchangeFee from "../../hooks/data/mutations/useUpdateInterchangeFee";

type Props = {
  isOpen: boolean;
  accountHolderCode: string;
  useInterchangeRate: boolean;
  onPlanChanged: (useInterchangeRate: boolean) => void;
  onError: () => void;
  onClose: () => void;
};

function ChangePlanConfirmationModal({
  isOpen,
  accountHolderCode,
  useInterchangeRate,
  onPlanChanged,
  onError,
  onClose,
}: Props): JSX.Element {
  const { mutate, isLoading } = useUpdateInterchangeFee();

  function handlePlanChange() {
    mutate(
      {
        accountHolderCode,
        useInterchangeRate: !useInterchangeRate,
      },
      {
        onSuccess() {
          onPlanChanged(!useInterchangeRate);
        },
        onError,
      }
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      title={"Change Rate Plan type"}
      onClose={onClose}
      titleClasses="text-left mb-6"
      panelClasses="min-w-[400px]"
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col break-words">
          <div>Are you sure you want to change the rate plan type?</div>
          <div>This will take effect immediately.</div>
        </div>
        <div className="flex justify-center gap-x-4">
          <Button
            variant="secondary"
            size="sm"
            className="w-16"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            size="sm"
            className="w-16"
            onClick={handlePlanChange}
            disabled={isLoading}
            icon={isLoading && <Spinner className="text-white" />}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangePlanConfirmationModal;
