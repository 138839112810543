export function capitalize(text: string): string {
  return text[0].toUpperCase() + text.slice(1);
}

export function isNullOrEmpty(message: string) {
  if (message === null || message.trim() === "") return true;
  return false;
}

export function camelCaseToNormal(text: string) {
  return text.replace(/([A-Z])/g, " $1");
}
