import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  getCurrentUserAccountWithExtraFields,
  UserAccount,
} from "../../../utils/userAccounts";

export default function useCurrentUserAccount(
  queryOptions?: UseQueryOptions<UserAccount, string>
) {
  return useQuery<UserAccount, string>(
    ["currenUser"],
    () => getCurrentUserAccountWithExtraFields(),
    {
      ...queryOptions,
    }
  );
}
