import { useParams } from "react-router-dom";
import JackrabbitPayLogo from "../../assets/logo-jackrabbit-pay-2023-color-final.svg";
import { DateTime } from "luxon";
import {
  formatISODateToLocal,
  longDateFormat,
  reportDateFormat,
  reportLongDateFormat,
  reportNameDateFormat,
} from "../../utils/dateTime";
import ReportTable from "./ReportTable";
import { formatAmount } from "../../utils/currency";
import Button from "../../components/Button";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import Info from "../../components/Info";
import { Fragment, useState } from "react";
import { useSnackbar } from "../../components/Snackbar";
import { downloadPayoutDetailCsv } from "../../utils/transaction";
import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import { capitalize } from "../../utils/string";
import { createEmptyPayoutDetailItem } from "../../services/transactionsService";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import { logCSVInteraction } from "../../utils/appInsightsLogger";
import useDepositSummaryReportQuery from "../../hooks/data/queries/useDepositSummaryReportQuery";
import useDepositDetailReportQuery from "../../hooks/data/queries/useDepositDetailReportQuery";

type ReportParams = {
  accountHolderCode: string;
  reportDate: string;
};

function DepositsReport(): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const { accountHolderCode, reportDate } = useParams<ReportParams>();
  const { data: accountConfigData } = useAccountConfigQuery(accountHolderCode);
  const inBalancePlatform = accountConfigData?.inBalancePlatform ?? false;
  const {
    data: depositsReportData,
    isLoading,
    isError,
    error,
  } = useDepositSummaryReportQuery({
    accountHolderCode: accountHolderCode || "",
    reportDate: reportDate || "",
  });

  const reportDateTime = DateTime.fromISO(reportDate ?? "");
  const lastDayOfMonth = reportDateTime.endOf("month");

  const [currentDetailDate, setCurrentDetailDate] = useState("");
  const [currentPayoutMethod, setCurrentPayoutMethod] = useState<string | null>(
    null
  );

  const reportData = depositsReportData;

  const currencyCode = reportData
    ? reportData.at(0)?.deposits?.at(0)?.currency
    : "";

  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
  });

  useDepositDetailReportQuery(
    {
      accountHolderCode: accountHolderCode || "",
      payoutMethod: currentPayoutMethod || "",
      reportDate: currentDetailDate,
    },
    {
      enabled: Boolean(
        currentDetailDate &&
          (inBalancePlatform ? true : currentPayoutMethod !== null)
      ),
      onError() {
        openSnackbar(
          "An error has occured while generating the csv file. Please try again.",
          {
            type: "error",
          }
        );
      },
      onSettled() {
        setCurrentDetailDate("");
        if (!inBalancePlatform) {
          setCurrentPayoutMethod("");
        }
      },
    }
  );

  const filterStatusColumn = (_: any, i: number) =>
    !(inBalancePlatform === false && i === 3);

  function generateSalesDaysFromBookingDate(bookingDate: string) {
    // if the booking date is null or undefined, just return the booking date
    if (!bookingDate) return bookingDate;

    let salesDates = [];

    // if the booking date is a monday, then display the prior three days before the booking date, otherwise, just display the day prior to the booking date
    let numberOfDays = DateTime.fromISO(bookingDate).weekday === 1 ? 3 : 1;

    // Descending loop from numberOfDays to 1, add dates to the salesDates array
    for (let i = numberOfDays; i > 0; i--) {
      salesDates.push(
        DateTime.fromISO(bookingDate).minus({ days: i }).toISODate()
      );
    }

    return formatMultipleDatesWithMonth(salesDates);
  }

  // This function formats multiple dates within a month with the name of the month and a day list delimited by commas
  // If there are multiple months in the list, then the second month is displayed as well
  // Examples:
  //   March 3, 4, 5
  //   April 30, March 1, 2
  function formatMultipleDatesWithMonth(isoDates: string[]): string {
    // Return an empty string if the input is null or undefined
    if (!isoDates) return "";

    let result = "";
    for (let i = 0; i < isoDates.length; i++) {
      let formattedDate = DateTime.fromISO(isoDates[i], {
        setZone: true,
      }).toFormat("MMMM d");

      // If the current date's month is the same as the previous date's month, remove the month from the formatted date
      if (
        i > 0 &&
        DateTime.fromISO(isoDates[i]).hasSame(
          DateTime.fromISO(isoDates[i - 1]),
          "month"
        )
      ) {
        formattedDate = formattedDate.split(" ")[1];
      }

      // Append a comma and a space to the result string if it is not empty
      if (result.length > 0) result += ", ";

      // Add the formatted date to the result string, followed by a comma and a space
      result += formattedDate;
    }

    return result;
  }

  function downloadDetailCsv(payoutMethod: string, date: string) {
    setCurrentDetailDate(date);
    setCurrentPayoutMethod(payoutMethod);
  }

  if (isError) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Error message={error} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Spinner className="!h-12 w-12 text-primary-700" />
        <div className="text-xl">Loading deposits report...</div>
      </div>
    );
  }

  if (depositsReportData.length === 0) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Info message={"No data is available."} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-y-2 py-8 px-4 lg:px-20">
      <img src={JackrabbitPayLogo} alt="Jackrabbit Pay Logo" className="w-96" />
      <h2 className="mb-4 text-2xl font-medium">
        {reportDateTime.toFormat("MM/yyyy")} Deposit Report
      </h2>
      <h3 className="mb-4 text-xl">
        {reportDateTime.toFormat(reportDateFormat)} through{" "}
        {lastDayOfMonth.toFormat(reportDateFormat)}
      </h3>
      <h3 className="mb-4 text-xl">Account: {accountHolderCode}</h3>
      {reportData?.map((x, i) => (
        <Fragment key={i}>
          <h3 className="flex w-1/2 justify-between text-xl">
            <span>{`Deposit Account: ${x.payoutMethod}`}</span>
          </h3>
          <ReportTable
            columns={[
              { label: "Sales Day(s)" },
              { label: "Date Deposit Initiated" },
              { label: "Amount" },
              { label: "Status" },
              { label: "Detail" },
            ].filter(filterStatusColumn)}
            data={
              x.deposits.map((y) =>
                [
                  generateSalesDaysFromBookingDate(y.bookingDate),
                  formatISODateToLocal(y.bookingDate, reportLongDateFormat),
                  formatAmount(y.amount),
                  y.status ? capitalize(y.status) : undefined,
                  <div className="flex justify-center">
                    <Button
                      variant="link"
                      className=""
                      onClick={() => {
                        logCSVInteraction(
                          "Deposit",
                          currentUserAccount?.username
                        );
                        downloadDetailCsv(x.payoutMethod, y.filterDate);
                      }}
                    >
                      Download
                    </Button>
                  </div>,
                ].filter(filterStatusColumn)
              ) ?? []
            }
            totalData={[
              "",
              "Total Deposits",
              formatAmount(x.totalAmount ?? 0),
              "",
              "",
            ].filter(filterStatusColumn)}
            tableClasses="w-1/2 self-center mb-6 print:w-full"
          />
        </Fragment>
      ))}
      <footer className="my-20 flex w-full justify-between text-xl print:text-base">
        <div>www.JackrabbitPay.com</div>
        <div>
          All transactions are in {accountConfigData?.timeZoneCode} Time zone
        </div>
        <div className="flex">All values are in {currencyCode}</div>
      </footer>
    </div>
  );
}

export default DepositsReport;
