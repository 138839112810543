import { DateTime } from "luxon";
import { DateFilterType } from "../services/transactionsService";

export const shortDateFormat = "MM/yyyy";
export const longDateFormat = "LLL dd, yyyy HH:mm:ss";
export const urlDateFormat = "MM/dd/yyyyTHH:mm:ss";
export const reportDateFormat = "MM/dd/yyyy";
export const reportLongDateFormat = "MM/dd/yyyy HH:mm:ss";
export const reportNameDateFormat = "yyyyMMdd";

export function getDateTimeFromDateFilterType(
  filterType: DateFilterType
): DateTime {
  const now = DateTime.now();
  switch (filterType) {
    case "Last24Hours":
      return now.minus({ hours: 24 });
    case "Last1Hour":
      return now.minus({ hours: 1 });
    case "Last7Days":
      return now.minus({ days: 7 }).startOf("day");
    case "Last30Days":
      return now.minus({ days: 30 }).startOf("day");
  }

  return now;
}

export function formatISODateToLocal(isoDate: string, format: string) {
  // Only format if not null or undefined.
  if (isoDate)
    return DateTime.fromISO(isoDate, { setZone: true }).toFormat(format);

  return isoDate;
}
