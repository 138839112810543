import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider as JotaiProvider } from "jotai";
import msalInstance from "./msal-instance";
import SnackbarProvider from "./components/Snackbar";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "react-datepicker/dist/react-datepicker.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (failureCount < 1) return true;
        return false;
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const CommonProviders = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <React.StrictMode>
      <JotaiProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>{children}</SnackbarProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </JotaiProvider>
    </React.StrictMode>
  );
};

root.render(
  <CommonProviders>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App msalInstance={msalInstance} />} />
      </Routes>
    </BrowserRouter>
  </CommonProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
