import { useParams } from "react-router-dom";
import useDailyReportQuery from "../../hooks/data/queries/useDailyReportQuery";
import { formatAmount } from "../../utils/currency";
import JackrabbitPayLogo from "../../assets/logo-jackrabbit-pay-2023-color-final.svg";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import Info from "../../components/Info";
import ReportTable from "./ReportTable";
import ArrowDownTrayIcon from "@heroicons/react/24/solid/ArrowDownTrayIcon";
import useReportCSVQuery from "../../hooks/data/queries/useReportCSVQuery";
import { useState } from "react";
import { useSnackbar } from "../../components/Snackbar";
import {
  downloadReportCsv,
  formatReportTransaction,
} from "../../utils/transaction";
import { sortStrings } from "../../utils/sorting";
import useAccountConfigQuery from "../../hooks/data/queries/useAccountConfigQuery";
import { DateTime } from "luxon";
import {
  formatISODateToLocal,
  reportNameDateFormat,
} from "../../utils/dateTime";
import {
  ReportTransaction,
  createEmptyReportTransaction,
} from "../../services/transactionsService";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import { logCSVInteraction } from "../../utils/appInsightsLogger";

type ReportParams = {
  accountHolderCode: string;
  reportDate: string;
};

function DailyReportDetail(): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const { accountHolderCode, reportDate } = useParams<ReportParams>();
  const [dailyReportCSVQueryEnabled, setDailyReportCSVQueryEnabled] =
    useState(false);
  const { openSnackbar } = useSnackbar({
    duration: 5000,
    position: "top",
  });
  const {
    data: dailyReport,
    isLoading,
    isError,
    error,
  } = useDailyReportQuery({
    accountHolderCode: accountHolderCode || "",
    reportDate: reportDate || "",
  });

  const { data: accountConfigData } = useAccountConfigQuery(accountHolderCode);
  const useInterchangeRate = accountConfigData?.useInterchangeRate ?? false;

  const { isFetching: isFetchingReportCSV } = useReportCSVQuery(
    "daily",
    {
      accountHolderCode: accountHolderCode || "",
      reportDate: reportDate || "",
    },
    {
      enabled: dailyReportCSVQueryEnabled,
      onSuccess(data) {
        const reportName = `jackrabbit_pay_daily_${formatISODateToLocal(
          reportDate || DateTime.now().toString(),
          reportNameDateFormat
        )}.csv`;

        // Format the data to match the csv format
        let items = data.map(formatReportTransaction);

        // Create a summary row
        const reportTransaction: ReportTransaction = {
          ...createEmptyReportTransaction(),
          creditCardProcessingFeeAmount: formatAmount(
            data
              .reduce(
                (sum, x) => sum + Number(x.creditCardProcessingFeeAmount),
                0
              )
              .toFixed(2)
          ).replaceAll(",", ""),
          technologyFee: formatAmount(
            data.reduce((sum, x) => sum + Number(x.technologyFee), 0).toFixed(2)
          ).replaceAll(",", ""),
          amountValue: formatAmount(
            data.reduce((sum, x) => sum + Number(x.amountValue), 0).toFixed(2)
          ).replaceAll(",", ""),
          transactionFeeAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.transactionFeeAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          businessNet: formatAmount(
            data.reduce((sum, x) => sum + Number(x.businessNet), 0).toFixed(2)
          ).replaceAll(",", ""),
          originalChargeAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.originalChargeAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          convenienceFeeAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.convenienceFeeAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          refundAmount: formatAmount(
            data.reduce((sum, x) => sum + Number(x.refundAmount), 0).toFixed(2)
          ).replaceAll(",", ""),
          processingFees: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.processingFees), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
          chargeBackAmount: formatAmount(
            data
              .reduce((sum, x) => sum + Number(x.chargeBackAmount), 0)
              .toFixed(2)
          ).replaceAll(",", ""),
        };

        // Add the summary row to the items
        items.push(reportTransaction);

        downloadReportCsv(items, reportName, useInterchangeRate);
      },
      onError() {
        openSnackbar(
          "An error has occured while generating the csv file. Please try again.",
          {
            type: "error",
          }
        );
      },
      onSettled() {
        setDailyReportCSVQueryEnabled(false);
      },
    }
  );
  const [reportYear, reportMonth, reportDay] = reportDate?.split("-") ?? [];

  if (isError) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Error message={error} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Spinner className="h-12 w-12 text-primary-700" />
        <div className="text-xl">Loading daily report...</div>
      </div>
    );
  }

  if (dailyReport.noContent) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center gap-y-4">
        <Info message={"No data is available."} />
        <Button onClick={() => window.close()}>Close this window</Button>
      </div>
    );
  }

  const alignRight = "text-right pr-3";

  const processingFeesValue = useInterchangeRate
    ? dailyReport.processingFees +
      dailyReport.techFees +
      dailyReport.convenienceFees
    : dailyReport.processingFees;

  const surchargeValue = useInterchangeRate ? 0 : dailyReport.convenienceFees;
  const technologyValue = useInterchangeRate ? 0 : dailyReport.techFees;

  return (
    <div className="flex flex-col items-center gap-y-2 py-8 px-4 print:px-0 lg:px-20">
      <img src={JackrabbitPayLogo} alt="Jackrabbit Pay Logo" className="w-96" />
      <h2 className="mb-4 text-2xl font-medium">Daily Processing Report</h2>
      <h3 className="text-xl">Account: {dailyReport?.accountHolderCode}</h3>
      <h3 className="mb-8 text-xl">
        Date: {`${reportMonth}/${reportDay}/${reportYear}`}
      </h3>
      <div className="mx-8 flex w-3/4 justify-between gap-x-6 text-xl print:mx-0 print:w-full print:text-base">
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Sales</div>
          <div>{formatAmount(dailyReport.sales)}</div>
        </div>
        <div>(+)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Surcharge</div>
          <div>{formatAmount(surchargeValue)}</div>
        </div>
        <div>(+)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Technology</div>
          <div>{formatAmount(technologyValue)}</div>
        </div>
        <div>(-)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Refunds</div>
          <div>{formatAmount(dailyReport.refunds)}</div>
        </div>
        <div>(=)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Net Sales</div>
          <div>{formatAmount(dailyReport.netSales)}</div>
        </div>
      </div>
      <div className="mb-8 w-full">
        <ReportTable
          columns={[
            { label: "Type" },
            { label: "Charges", className: alignRight },
            { label: "Charges Total", className: alignRight },
            { label: "Refunds", className: alignRight },
            { label: "Refunds Total", className: alignRight },
            { label: "Chargebacks", className: alignRight },
            { label: "Chargebacks Total", className: alignRight },
          ]}
          data={dailyReport.methods
            .sort((a, b) => sortStrings(a.type, b.type))
            .map((method) => [
              method.type,
              method.charges,
              formatAmount(method.chargesAmount),
              method.refunds,
              formatAmount(method.refundsAmount),
              method.chargebacks,
              formatAmount(method.chargebacksAmount),
            ])}
        />
      </div>
      <div className="mb-8 text-xl">
        Transactions: {dailyReport.transactions}
      </div>
      <div className="mb-12 flex w-3/4 justify-between gap-x-6 text-xl print:w-full print:text-base">
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Net Sales</div>
          <div>{formatAmount(dailyReport.totalCharges)}</div>
        </div>
        <div>(-)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Processing</div>
          <div>{formatAmount(processingFeesValue)}</div>
        </div>
        <div>(-)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Technology</div>
          <div>{formatAmount(technologyValue)}</div>
        </div>
        <div>(-)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Chargebacks</div>
          <div>{formatAmount(dailyReport.chargebacks)}</div>
        </div>
        <div>(=)</div>
        <div className="flex flex-col items-center">
          <div className="underline underline-offset-2">Net</div>
          <div>{formatAmount(dailyReport.deposit)}</div>
        </div>
      </div>
      {dailyReport.totalCredits !== 0 && (
        <h3 className="pb-8 text-xl">{`An adjustment of (${
          dailyReport.totalCredits > 0 ? "+" : "-"
        }) ${formatAmount(
          dailyReport.totalCredits
        )} has been applied to your deposit.`}</h3>
      )}
      <Button
        onClick={() => {
          logCSVInteraction("Daily", currentUserAccount?.username);
          setDailyReportCSVQueryEnabled(true);
        }}
        size="sm"
        variant="secondary"
        disabled={isFetchingReportCSV}
        className="px-3 py-1 print:hidden"
        icon={isFetchingReportCSV ? <Spinner /> : <ArrowDownTrayIcon />}
      >
        Download Transactions
      </Button>
      <footer className="my-20 flex w-full justify-between text-xl">
        <div>www.JackrabbitPay.com</div>
        <div>
          All transactions are in {accountConfigData?.timeZoneCode} Time zone
        </div>
        <div className="flex">All values are in {dailyReport.currencyCode}</div>
      </footer>
    </div>
  );
}

export default DailyReportDetail;
