import { twJoin } from "tailwind-merge";
import { Tab as OriginalTab } from "@headlessui/react";
import React, { Fragment } from "react";

/**
 * Tab
 */
type Props = {
  index: number;
  disabled?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
};

function Tab({ index, disabled, isLoading, children }: Props): JSX.Element {
  function getTabClasses(
    selected: boolean,
    isLoading: boolean = false,
    disabled: boolean = false
  ) {
    if (selected) {
      return disabled || isLoading
        ? "bg-gray-300 text-white border-gray-300 cursor-not-allowed"
        : "bg-primary text-white border-primary";
    }
    return disabled || isLoading
      ? "bg-white text-gray-300 border-gray-300 cursor-not-allowed"
      : "bg-white text-primary border-primary";
  }

  return (
    <OriginalTab as={Fragment} key={index}>
      {({ selected }) => (
        <button
          className={twJoin(
            "flex items-center justify-center overflow-hidden rounded-lg border px-2 py-2 focus-visible:outline-none",
            getTabClasses(selected && !isLoading, isLoading, disabled),
            index === 0 ? "rounded-r-none border-r-0" : "rounded-l-none"
          )}
        >
          {children}
        </button>
      )}
    </OriginalTab>
  );
}

/**
 * Tab.Group
 */
type TabGroupProps = {
  selectedIndex: number;
  onChange: (index: number) => void;
  children: React.ReactNode;
};

function TabGroup({ selectedIndex, onChange, children }: TabGroupProps) {
  return (
    <OriginalTab.Group selectedIndex={selectedIndex} onChange={onChange} manual>
      {children}
    </OriginalTab.Group>
  );
}

/**
 * Tab.List
 */
type TabListProps = {
  children: React.ReactNode;
};

function TabList({ children }: TabListProps) {
  const gridColsClass = `grid-cols-${React.Children.count(children)}`;

  return (
    <OriginalTab.List className={`mt-2 grid w-full ${gridColsClass}`}>
      {React.Children.map(children, (child, i) => child)}
    </OriginalTab.List>
  );
}

/**
 * Tab.Panels
 */
type TabPanelsProps = {
  className?: string;
  children: React.ReactNode;
};

function TabPanels({ className = "", children }: TabPanelsProps) {
  return (
    <OriginalTab.Panels className={className}>
      {React.Children.map(children, (child) => (
        <OriginalTab.Panel unmount={false}>{child}</OriginalTab.Panel>
      ))}
    </OriginalTab.Panels>
  );
}

Tab.Group = TabGroup;
Tab.List = TabList;
Tab.Panels = TabPanels;

export default Tab;
