export function waitForElement(selector: string): Promise<Element> {
  return new Promise((resolve) => {
    const foundElement = document.querySelector(selector);
    if (foundElement) return foundElement;

    const observer = new MutationObserver((mutations) => {
      const foundElement = document.querySelector(selector);
      if (foundElement) {
        observer.disconnect();
        resolve(foundElement);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
