import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import RadioButton from "../../components/RadioButton";
import Select from "../../components/Select";
import { useSnackbar } from "../../components/Snackbar";
import useUpdateMerchantConfigMutation from "../../hooks/data/mutations/useUpdateMerchantConfigMutation";
import { AccountHolder } from "../../services/accountService";
import merchantStatus from "../../data/merchantStatus.json";
import Panel from "./Panel";
import EditControls from "./EditControls";
import Switch from "../../components/Switch";
import { Popover } from "@headlessui/react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import HasAccess, { csrRoles } from "../../components/HasAccess";
import useTimezonesQuery from "../../hooks/data/queries/useTimezonesQuery";

type MerchantConfigData = Pick<
  AccountHolder,
  | "merchantStatus"
  | "adyenCaptureDelay"
  | "techFeeRate"
  | "convFee"
  | "convFeeRate"
  | "timeZoneCode"
  | "useTransAmountLimit"
  | "useACHTransAmountLimit"
  | "transAmountLimit"
  | "achTransAmountLimit"
  | "useDailyTransAmountLimit"
  | "useACHDailyTransAmountLimit"
  | "dailyTransAmountLimit"
  | "achDailyTransAmountLimit"
  | "useTechFee"
  | "useConvFee"
  | "techFeeRate"
  | "inBalancePlatform"
  | "migrated"
  | "stores"
> & { shopperStatement: string };

type Props = {
  accountHolderId: string;
  accountHolderCode: string;
  merchantConfigData: MerchantConfigData | undefined;
  country: string;
  isLoading: boolean;
  renderMe: boolean;
};

function MerchantConfigurations({
  accountHolderId,
  accountHolderCode,
  merchantConfigData,
  country,
  isLoading,
  renderMe,
}: Props): JSX.Element {
  const { mutate: updateMerchantConfig, isLoading: isUpdatingMerchantConfig } =
    useUpdateMerchantConfigMutation(accountHolderCode);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedMerchantConfigData, setEditedMerchantConfigData] =
    useState<MerchantConfigData | null>(null);

  const [usingTransAmountLimit, setUsingTransAmountLimit] = useState(
    Boolean(merchantConfigData?.useTransAmountLimit)
  );
  const [usingACHTransAmountLimit, setUsingACHTransAmountLimit] = useState(
    Boolean(merchantConfigData?.useACHTransAmountLimit)
  );

  const { data: timezones, isLoading: isLoadingTimezones } = useTimezonesQuery(
    country,
    { enabled: Boolean(country) }
  );

  const [usingDailyTransAmountLimit, setUsingDailyTransAmountLimit] = useState(
    Boolean(merchantConfigData?.useDailyTransAmountLimit)
  );
  const [usingACHDailyTransAmountLimit, setUsingACHDailyTransAmountLimit] =
    useState(Boolean(merchantConfigData?.useACHDailyTransAmountLimit));

  const [usingTechFee, setUsingTechFee] = useState(
    Boolean(merchantConfigData?.useTechFee)
  );

  const [usingConvFee, setUsingConvFee] = useState(
    Boolean(merchantConfigData?.useConvFee)
  );

  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  const merchantStatusRef = useRef<HTMLSelectElement>(null);

  const convFeeValid =
    isLoading || +editedMerchantConfigData?.convFeeRate! <= 4;
  const saveButtonEnabled =
    (convFeeValid &&
      (merchantConfigData?.merchantStatus !==
        editedMerchantConfigData?.merchantStatus ||
        merchantConfigData?.adyenCaptureDelay !==
          editedMerchantConfigData?.adyenCaptureDelay ||
        merchantConfigData?.shopperStatement !==
          editedMerchantConfigData?.shopperStatement ||
        merchantConfigData?.techFeeRate !==
          editedMerchantConfigData?.techFeeRate ||
        merchantConfigData?.convFeeRate !==
          editedMerchantConfigData?.convFeeRate ||
        merchantConfigData?.useTransAmountLimit !==
          editedMerchantConfigData?.useTransAmountLimit ||
        merchantConfigData?.useACHTransAmountLimit !==
          editedMerchantConfigData?.useACHTransAmountLimit ||
        merchantConfigData?.transAmountLimit !==
          editedMerchantConfigData?.transAmountLimit ||
        merchantConfigData?.achTransAmountLimit !==
          editedMerchantConfigData?.achTransAmountLimit ||
        merchantConfigData?.useDailyTransAmountLimit !==
          editedMerchantConfigData?.useDailyTransAmountLimit ||
        merchantConfigData?.useACHDailyTransAmountLimit !==
          editedMerchantConfigData?.useACHDailyTransAmountLimit ||
        merchantConfigData?.dailyTransAmountLimit !==
          editedMerchantConfigData?.dailyTransAmountLimit ||
        merchantConfigData?.achDailyTransAmountLimit !==
          editedMerchantConfigData?.achDailyTransAmountLimit ||
        merchantConfigData?.timeZoneCode !==
          editedMerchantConfigData?.timeZoneCode ||
        merchantConfigData?.useConvFee !==
          editedMerchantConfigData?.useConvFee ||
        merchantConfigData?.useTechFee !==
          editedMerchantConfigData?.useTechFee)) ||
    merchantConfigData?.inBalancePlatform !==
      editedMerchantConfigData?.inBalancePlatform;

  useEffect(() => {
    if (merchantConfigData && !isUpdatingMerchantConfig) {
      setEditedMerchantConfigData(merchantConfigData);
    }
  }, [merchantConfigData, isUpdatingMerchantConfig]);

  useEffect(() => {
    merchantStatusRef.current?.focus();
  }, [isEditing]);

  function handleUpdateMerchantConfig() {
    updateMerchantConfig(
      {
        accountHolderId,
        merchantStatus: editedMerchantConfigData?.merchantStatus ?? "",
        adyenCaptureDelay: editedMerchantConfigData?.adyenCaptureDelay ?? "",
        shopperStatement: editedMerchantConfigData?.shopperStatement ?? "",
        convFee: "F",
        convFeeRate: editedMerchantConfigData?.convFeeRate ?? "",
        techFeeRate: editedMerchantConfigData?.techFeeRate ?? "",
        timeZoneCode: editedMerchantConfigData?.timeZoneCode ?? "",
        useTransAmountLimit:
          editedMerchantConfigData?.useTransAmountLimit ?? false,
        useACHTransAmountLimit:
          editedMerchantConfigData?.useACHTransAmountLimit ?? false,
        transAmountLimit: editedMerchantConfigData?.transAmountLimit ?? "",
        achTransAmountLimit:
          editedMerchantConfigData?.achTransAmountLimit ?? "",
        useDailyTransAmountLimit:
          editedMerchantConfigData?.useDailyTransAmountLimit ?? false,
        useACHDailyTransAmountLimit:
          editedMerchantConfigData?.useACHDailyTransAmountLimit ?? false,
        dailyTransAmountLimit:
          editedMerchantConfigData?.dailyTransAmountLimit ?? "",
        achDailyTransAmountLimit:
          editedMerchantConfigData?.achDailyTransAmountLimit ?? "",
        useConvFee: editedMerchantConfigData?.useConvFee ?? false,
        useTechFee: editedMerchantConfigData?.useTechFee ?? false,
        inBalancePlatform: editedMerchantConfigData?.inBalancePlatform ?? false,
      },
      {
        onSuccess() {
          openSnackbar("Merchant config was successfully updated.", {
            type: "success",
            duration: 3000,
          });
          setIsEditing(false);
        },
        onError() {
          openSnackbar("Failed to update merchant config. Please try again.", {
            type: "error",
            duration: 3000,
          });
        },
      }
    );
  }

  function handleEditableFieldChange(field: string, value: any) {
    setEditedMerchantConfigData({
      ...editedMerchantConfigData!,
      [field]: value,
    });
  }

  return (
    <Panel className="col-span-4">
      <Panel.Header title="Merchant Configurations">
        <EditControls
          customId="editMerchantConfigurationsButton"
          isEditing={isEditing}
          isSaving={isUpdatingMerchantConfig}
          editButtonProps={{
            disabled: isLoading,
            onClick: () => setIsEditing(true),
          }}
          saveButtonProps={{
            onClick: () => handleUpdateMerchantConfig(),
            disabled: !saveButtonEnabled,
          }}
          cancelButtonProps={{
            onClick: () => {
              setIsEditing(false);
              setEditedMerchantConfigData(merchantConfigData ?? null);
            },
          }}
        />
      </Panel.Header>

      <Panel.Content>
        <div className="grid grid-cols-[1fr_1.3fr] grid-rows-[1fr_1fr_1fr_1fr_auto] gap-x-4 gap-y-8 pt-4">
          <span className="font-medium">Merchant Status</span>
          <Select
            ref={merchantStatusRef}
            id="merchantStatus"
            options={merchantStatus}
            value={editedMerchantConfigData?.merchantStatus ?? ""}
            onChange={(e) =>
              handleEditableFieldChange("merchantStatus", e.target.value)
            }
            disabled={!isEditing || !renderMe}
          />
          <span className="font-medium">Capture Delay Hours</span>
          <Input
            id="captureDelayHours"
            type="number"
            disableDecimals
            value={editedMerchantConfigData?.adyenCaptureDelay ?? ""}
            onChange={(e) =>
              handleEditableFieldChange("adyenCaptureDelay", e.target.value)
            }
            disabled={!isEditing}
          />
          <span className="font-medium">Shopper Statement</span>
          <Input
            id="shopperStatement"
            disabled={!isEditing || merchantConfigData?.stores.length === 0}
            value={editedMerchantConfigData?.shopperStatement ?? ""}
            onChange={(e) =>
              handleEditableFieldChange("shopperStatement", e.target.value)
            }
            maxLength={22}
          />

          <span className="pt-1 font-medium">ACH Transaction Limit</span>
          <div className="flex w-full items-center justify-between gap-x-2">
            <Switch
              checked={Boolean(
                editedMerchantConfigData?.useACHTransAmountLimit
              )}
              onChange={() => {
                setUsingACHTransAmountLimit((p) => !p);
                handleEditableFieldChange(
                  "useACHTransAmountLimit",
                  !usingACHTransAmountLimit
                );
              }}
              disabled={!isEditing}
              label=""
              labelProps={{ className: "font-medium w-100 mr-0" }}
            />
            <Popover className="relative">
              <Tippy
                className="mb-3"
                placement="right"
                content="Zero means no limit"
              >
                <Input
                  id="achTransAmountLimit"
                  type="string"
                  label="Amount"
                  containerProps={{
                    className: "w-[85px]",
                  }}
                  disabled={!isEditing}
                  value={editedMerchantConfigData?.achTransAmountLimit ?? ""}
                  onChange={(e) => {
                    if (e.target.value && parseFloat(e.target.value) < 0) {
                      openSnackbar(
                        "ACH Transaction Amount Limit must be >= 0",
                        {
                          type: "error",
                          duration: 3000,
                        }
                      );
                    } else
                      handleEditableFieldChange(
                        "achTransAmountLimit",
                        e.target.value
                      );
                  }}
                />
              </Tippy>
            </Popover>
          </div>

          <span className="pt-1 font-medium">ACH Daily Volume Limit</span>
          <div className="flex w-full items-center justify-between gap-x-2">
            <Switch
              checked={Boolean(
                editedMerchantConfigData?.useACHDailyTransAmountLimit
              )}
              onChange={() => {
                setUsingACHDailyTransAmountLimit((p) => !p);
                handleEditableFieldChange(
                  "useACHDailyTransAmountLimit",
                  !usingACHDailyTransAmountLimit
                );
              }}
              disabled={!isEditing}
              label=""
              labelProps={{ className: "font-medium w-100 mr-0" }}
            />
            <Popover className="relative">
              <Tippy
                className="mb-3"
                placement="right"
                content="Zero means no limit"
              >
                <Input
                  id="achDailyTransAmountLimit"
                  type="string"
                  label="Amount"
                  containerProps={{
                    className: "w-[85px]",
                  }}
                  disabled={!isEditing}
                  value={
                    editedMerchantConfigData?.achDailyTransAmountLimit ?? ""
                  }
                  onChange={(e) => {
                    if (e.target.value && parseFloat(e.target.value) < 0) {
                      openSnackbar("ACH Daily Processing Limit must be >= 0", {
                        type: "error",
                        duration: 3000,
                      });
                    } else
                      handleEditableFieldChange(
                        "achDailyTransAmountLimit",
                        e.target.value
                      );
                  }}
                />
              </Tippy>
            </Popover>
          </div>

          <span className="pt-1 font-medium">
            Card/Wallet Transaction Limit
          </span>
          <div className="flex w-full items-center justify-between gap-x-2">
            <Switch
              checked={Boolean(editedMerchantConfigData?.useTransAmountLimit)}
              onChange={() => {
                setUsingTransAmountLimit((p) => !p);
                handleEditableFieldChange(
                  "useTransAmountLimit",
                  !usingTransAmountLimit
                );
              }}
              disabled={!isEditing}
              label=""
              labelProps={{ className: "font-medium w-100 mr-0" }}
            />
            <Popover className="relative">
              <Tippy
                className="mb-3"
                placement="right"
                content="Zero means no limit"
              >
                <Input
                  id="transAmountLimit"
                  type="string"
                  label="Amount"
                  containerProps={{
                    className: "w-[85px]",
                  }}
                  disabled={!isEditing}
                  value={editedMerchantConfigData?.transAmountLimit ?? ""}
                  onChange={(e) => {
                    if (e.target.value && parseFloat(e.target.value) < 0) {
                      openSnackbar("Transaction Amount Limit must be >= 0", {
                        type: "error",
                        duration: 3000,
                      });
                    } else
                      handleEditableFieldChange(
                        "transAmountLimit",
                        e.target.value
                      );
                  }}
                />
              </Tippy>
            </Popover>
          </div>

          <span className="pt-1 font-medium">
            Card/Wallet Daily Volume Limit
          </span>
          <div className="flex w-full items-center justify-between gap-x-2">
            <Switch
              checked={Boolean(
                editedMerchantConfigData?.useDailyTransAmountLimit
              )}
              onChange={() => {
                setUsingDailyTransAmountLimit((p) => !p);
                handleEditableFieldChange(
                  "useDailyTransAmountLimit",
                  !usingDailyTransAmountLimit
                );
              }}
              disabled={!isEditing}
              label=""
              labelProps={{ className: "font-medium w-100 mr-0" }}
            />
            <Popover className="relative">
              <Tippy
                className="mb-3"
                placement="right"
                content="Zero means no limit"
              >
                <Input
                  id="dailyTransAmountLimit"
                  type="string"
                  label="Amount"
                  containerProps={{
                    className: "w-[85px]",
                  }}
                  disabled={!isEditing}
                  value={editedMerchantConfigData?.dailyTransAmountLimit ?? ""}
                  onChange={(e) => {
                    if (e.target.value && parseFloat(e.target.value) < 0) {
                      openSnackbar("Daily Processing Limit must be >= 0", {
                        type: "error",
                        duration: 3000,
                      });
                    } else
                      handleEditableFieldChange(
                        "dailyTransAmountLimit",
                        e.target.value
                      );
                  }}
                />
              </Tippy>
            </Popover>
          </div>

          <span className="font-medium">Tech Fee Rate</span>
          <div className="flex w-full items-center justify-between gap-y-2">
            <Switch
              checked={Boolean(editedMerchantConfigData?.useTechFee)}
              onChange={() => {
                setUsingTechFee((p) => !p);
                handleEditableFieldChange("useTechFee", !usingTechFee);
              }}
              disabled={!isEditing}
              label=""
              labelProps={{ className: "font-medium w-100 mr-0" }}
            />
            <Input
              id="techFeeRate"
              type="number"
              label="Percentage"
              containerProps={{
                className: "w-[85px]",
              }}
              value={editedMerchantConfigData?.techFeeRate ?? ""}
              onWheel={(e) => (e.target as HTMLElement).blur()}
              onChange={(e) => {
                if (e.target.value && parseFloat(e.target.value) <= 0) {
                  openSnackbar("Tech Fee Rate must be > 0", {
                    type: "error",
                    duration: 3000,
                  });
                } else handleEditableFieldChange("techFeeRate", e.target.value);
              }}
              disabled={!isEditing}
            />
          </div>

          <span className="font-medium">Surcharge Rate</span>
          <div className="grid grid-cols-3">
            <div>
              <Switch
                checked={Boolean(editedMerchantConfigData?.useConvFee)}
                onChange={() => {
                  setUsingConvFee((p) => !p);
                  handleEditableFieldChange("useConvFee", !usingConvFee);
                }}
                disabled={!isEditing}
                label=""
                labelProps={{ className: "font-medium w-100 mr-0" }}
              />
            </div>
            <div className="col-span-2 ml-7 text-sm">
              <div className="grid-col-3 grid">
                <div className="col-span-1 col-start-2">
                  <Input
                    id="rateFixedValue"
                    type="number"
                    label="Percentage"
                    value={editedMerchantConfigData?.convFeeRate ?? ""}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    onChange={(e) => {
                      if (e.target.value && parseFloat(e.target.value) <= 0) {
                        openSnackbar(
                          "Surcharge Fixed Rate must be greater than 0",
                          {
                            type: "error",
                            duration: 4000,
                          }
                        );
                      } else if (
                        e.target.value &&
                        parseFloat(e.target.value) > 4
                      ) {
                        openSnackbar(
                          "Surcharge Fixed Rate cannot be greater than 4",
                          {
                            type: "error",
                            duration: 4000,
                          }
                        );
                      } else {
                        handleEditableFieldChange(
                          "convFeeRate",
                          e.target.value
                        );
                      }
                    }}
                    disabled={!isEditing}
                    containerProps={{
                      className: "w-[85px] ml-12",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <HasAccess allowedRoles={[...csrRoles]}>
            <span className="font-medium">Timezone</span>
            <Select
              id="timezone"
              value={editedMerchantConfigData?.timeZoneCode}
              options={
                timezones?.map((x) => ({
                  value: x.code,
                  label: x.name,
                })) ?? []
              }
              onChange={(e) =>
                handleEditableFieldChange("timeZoneCode", e.target.value)
              }
              loading={isLoadingTimezones}
              disabled={!isEditing}
              className="w-full"
            />
          </HasAccess>
        </div>
      </Panel.Content>
    </Panel>
  );
}

export default MerchantConfigurations;
