import UsFlag from "../assets/us.svg";
import CaFlag from "../assets/ca.svg";
import GbFlag from "../assets/gb.svg";
import NLflag from "../assets/nl.svg";
import AUflag from "../assets/au.svg";
import NZflag from "../assets/nz.svg";

type Props = {
  country: string;
};

function getSvg(country: string) {
  switch (country) {
    case "US":
    case "PR":
      return UsFlag;
    case "CA":
      return CaFlag;
    case "UK":
    case "GB":
      return GbFlag;
    case "NL":
      return NLflag;
    case "AU":
      return AUflag;
    case "NZ":
      return NZflag;
  }
  return "";
}

function getCountryName(country: string) {
  switch (country) {
    case "US":
      return "United States";
    case "CA":
      return "Canada";
    case "UK":
    case "GB":
      return "Great Britain";
    case "NL":
      return "Netherlands";
    case "AU":
      return "Australia";
    case "NZ":
      return "New Zealand";
  }
  return "";
}

function CountryFlagIcon({ country }: Props): JSX.Element {
  const svg = getSvg(country);

  if (!svg) {
    return <span />;
  }

  return (
    <img src={svg} alt={getCountryName(country)} className="w-6 rounded" />
  );
}

export default CountryFlagIcon;
