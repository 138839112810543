import { useMemo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import AccountHoldersTableFilter from "./AccountHoldersTableFilter";
import OnboardingClientModal from "./OnboardingClientModal";
import useAccountHoldersQuery from "../../hooks/data/queries/useAccountHoldersQuery";
import {
  AccountHolder,
  AccountHoldersFilter,
} from "../../services/accountService";
import AddNewLocationModal from "./AddNewLocationModal";
import HasAccess, { csrAdminRoles } from "../../components/HasAccess";
import { ChangePageEvent } from "../../types";
import { useQueryClient } from "@tanstack/react-query";
import { getPaginatorPage } from "../../utils/pagination";
import { OnChangeFn, SortingState } from "@tanstack/react-table";
import Error from "../../components/Error";
import { useAtom } from "jotai";
import { accountHoldersFilterAtom } from "../../atoms";
import { getEmptyAccountHolder } from "../../utils/accounts";
import { getAccountHoldersTableColumns } from "./accountHoldersTableColumns";
import Table from "../../components/Table";
import "../../index.css";
import MigrateConfirmationModal from "./MigrateConfirmationModal";
import DropdownButton from "../../components/DropdownButton";
import Switch from "../../components/Switch";
import ClientStats from "../../components/ClientStats";

export type AccountHoldersTableMeta = {
  onAddLocationClick: (accountHolder: AccountHolder) => void;
  onInviteUserClick: (accountHolder: AccountHolder) => void;
  onMigrateClick: (accountHolder: AccountHolder) => void;
};

export type OnboardingType = "classic" | "balance";

function OnboardingSummary(): JSX.Element {
  const [filter, setFilter] = useAtom(accountHoldersFilterAtom);
  const [totalPages, setTotalPages] = useState(1);
  const [addNewLocationModalIsOpen, setAddNewLocationModalIsOpen] =
    useState<boolean>(false);
  const [currentAccountHolder, setCurrentAccountHolder] =
    useState<AccountHolder | null>(null);
  const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: accountHoldersData,
    isLoading,
    error,
  } = useAccountHoldersQuery(
    {
      accountHolderCode: filter.accountHolderCode,
      status: filter.status,
      showClosed: filter.showClosed,
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      sort: filter.sort,
    },
    {
      onSuccess: (data) => setTotalPages(data.totalPages),
    }
  );

  const memoAccountHolders = useMemo(() => {
    if (isLoading && !accountHoldersData) {
      return Array.from<AccountHolder>({ length: filter.pageSize }).fill(
        getEmptyAccountHolder()
      );
    }
    return accountHoldersData?.pageElements ?? [];
  }, [accountHoldersData, isLoading, filter.pageSize]);

  const onboardClassic: boolean =
    (process.env.REACT_APP_FF_ONBOARD_CLASSIC?.toLowerCase() ?? "true") ===
    "true";
  const onboardBalance: boolean =
    (process.env.REACT_APP_FF_ONBOARD_BALANCE?.toLowerCase() ?? "true") ===
    "true";

  let onboardingOptions = [];

  if (onboardClassic) {
    onboardingOptions.push({
      text: "Classic platform",
      onClick: () => {
        setOnboardingType("classic");
      },
    });
  }

  if (onboardBalance) {
    onboardingOptions.push({
      text: "Balance platform",
      onClick: () => {
        setOnboardingType("balance");
      },
    });
  }

  function handleFilterChange({
    accountHolderCode,
    status,
  }: AccountHoldersFilter) {
    setFilter({
      ...filter,
      accountHolderCode,
      status,
      pageNumber: 1,
    });
  }

  function handlePageChange(event: ChangePageEvent, totalPages: number = 1) {
    setFilter({
      ...filter,
      pageNumber: getPaginatorPage(filter.pageNumber, totalPages, event),
    });
  }

  function handlePageSizeChange(pageSize: number) {
    setFilter({
      ...filter,
      pageNumber: 1,
      pageSize,
    });
  }

  function handleSortChange(sort: SortingState) {
    setFilter({
      ...filter,
      sort,
    });
  }

  function handleShowClosedChange() {
    setFilter({
      ...filter,
      showClosed: !filter.showClosed,
    });
  }

  const [onboardingType, setOnboardingType] = useState<OnboardingType | null>(
    null
  );

  return (
    <div className="flex flex-col px-6">
      <h1 className="text-3xl">Clients</h1>
      <ClientStats />
      <div className="my-6 flex flex-wrap gap-y-12 gap-x-12">
        <AccountHoldersTableFilter
          filter={{
            accountHolderCode: filter.accountHolderCode,
            status: filter.status,
            showClosed: filter.showClosed,
          }}
          onFilterChange={handleFilterChange}
        />
        <Switch
          checked={filter.showClosed}
          onChange={handleShowClosedChange}
          label="Show Closed"
          labelProps={{
            className: "font-semibold",
          }}
          showLegend={false}
        />
        <HasAccess allowedRoles={[...csrAdminRoles]}>
          <DropdownButton
            size="sm"
            className="pushRight flex"
            icon={<ChevronDownIcon />}
            iconPosition="right"
            options={onboardingOptions}
          >
            Create New Client
          </DropdownButton>
        </HasAccess>
      </div>
      {error ? (
        <Error message={error} />
      ) : (
        <Table
          id="accountHolders"
          data={memoAccountHolders}
          columns={getAccountHoldersTableColumns()}
          isLoading={isLoading}
          noRowsText="Your search returned no results. Please modify the criteria and try again."
          sorting={filter.sort}
          setSorting={handleSortChange as OnChangeFn<SortingState>}
          paginatorOptions={{
            currentPage: filter.pageNumber,
            pageSize: filter.pageSize,
            totalPages,
            activeNextPage: accountHoldersData?.activeNextPage ?? false,
            activePrevPage: accountHoldersData?.activePrevPage ?? false,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
          meta={{
            onInviteUserClick: (accountHolder: AccountHolder) => {
              setOnboardingType("classic");
              setCurrentAccountHolder(accountHolder);
            },
            onAddLocationClick: (accountHolder: AccountHolder) => {
              setAddNewLocationModalIsOpen(true);
              setCurrentAccountHolder(accountHolder);
            },
            onMigrateClick: (accountHolder: AccountHolder) => {
              setMigrateModalIsOpen(true);
              setCurrentAccountHolder(accountHolder);
            },
          }}
        />
      )}
      <OnboardingClientModal
        onboardingType={onboardingType}
        currentAccountHolder={currentAccountHolder}
        onClose={() => {
          setOnboardingType(null);
          setCurrentAccountHolder(null);
        }}
      />
      {currentAccountHolder && (
        <AddNewLocationModal
          isOpen={addNewLocationModalIsOpen}
          accountHolderCode={currentAccountHolder.adyenAccountHolderCode}
          onClose={() => {
            setAddNewLocationModalIsOpen(false);
            setCurrentAccountHolder(null);
          }}
          onLocationAdded={() =>
            queryClient.invalidateQueries(["accountHolders"])
          }
        />
      )}
      <MigrateConfirmationModal
        isOpen={migrateModalIsOpen}
        accountHolderCode={currentAccountHolder?.adyenAccountHolderCode ?? ""}
        onAccountHolderMigrated={() => {
          queryClient.invalidateQueries(["accountHolders"]);
          setMigrateModalIsOpen(false);
        }}
        onClose={() => setMigrateModalIsOpen(false)}
      />
    </div>
  );
}

export default OnboardingSummary;
