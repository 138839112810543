import { useState } from "react";
import Checkbox from "../../components/Checkbox";
import Input from "../../components/Input";
import Popover from "../../components/Popover";
import Switch from "../../components/Switch";
import { formatAmount } from "../../utils/currency";
import EditControls from "./EditControls";
import { RatePlan, RatePlanType } from "./RatePlans";

type RatePlanRowProps = {
  ratePlan: RatePlan;
  ratePlanType: RatePlanType;
  index: number;
  isEditing: boolean;
  currentEditingRatePlan: RatePlan | null;
  setCurrentEditingRatePlan: (ratePlan: RatePlan | null) => void;
  addToInputRefs: (e: HTMLInputElement) => void;
  isUpdatingRatePlan: boolean;
  handleEditClick: (ratePlan: RatePlan, index: number) => void;
  handleUpdateRatePlan: () => void;
  currentEditingSaveButtonEnabled: boolean;
  isMasterSurchargeEnabled: boolean;
};

function RatePlanRow({
  ratePlan,
  ratePlanType,
  index,
  isEditing,
  currentEditingRatePlan,
  setCurrentEditingRatePlan,
  addToInputRefs,
  isUpdatingRatePlan,
  handleEditClick,
  handleUpdateRatePlan,
  currentEditingSaveButtonEnabled,
  isMasterSurchargeEnabled,
}: RatePlanRowProps) {
  const [surchargeSwitchEl, setSurchargeSwitchEl] =
    useState<HTMLDivElement | null>(null);
  const [showSurchargeToggle, setShowSurchargeToggle] = useState(false);
  const [surchargeIsChecked, setSurchargeIsChecked] = useState(false);

  return (
    <div
      className={`${
        ratePlanType === "Flat Rate Plan" ? "grid-cols-6" : "grid-cols-5"
      } grid items-center gap-x-5`}
    >
      <span className="font-medium capitalize">{ratePlan.label}</span>
      <div className="px-6">
        <Checkbox
          key={ratePlan.data?.paymentMethodType}
          checked={
            isEditing
              ? currentEditingRatePlan?.data?.allowedMethod
              : ratePlan.data?.allowedMethod
          }
          onChange={(e) => {
            setCurrentEditingRatePlan({
              ...currentEditingRatePlan!,
              data: {
                ...currentEditingRatePlan?.data!,
                allowedMethod: e.target.checked,
                surcharge: e.target.checked
                  ? currentEditingRatePlan?.data?.surcharge!
                  : false,
              },
            });
          }}
          disabled={!isEditing}
        />
      </div>
      <Input
        id="creditCardRatePlanPercentage"
        type="number"
        ref={addToInputRefs}
        value={
          isEditing
            ? currentEditingRatePlan?.data?.percentageValue
            : formatAmount(ratePlan.data?.percentageValue)
        }
        onChange={(e) =>
          setCurrentEditingRatePlan({
            ...currentEditingRatePlan!,
            data: {
              ...currentEditingRatePlan?.data!,
              percentageValue: e.target.value,
            },
          })
        }
        disabled={!isEditing}
      />
      <Input
        id="creditCardRatePlanFixedAmount"
        type="number"
        value={
          isEditing
            ? currentEditingRatePlan?.data?.fixedAmount
            : formatAmount(ratePlan.data?.fixedAmount)
        }
        onChange={(e) =>
          setCurrentEditingRatePlan({
            ...currentEditingRatePlan!,
            data: {
              ...currentEditingRatePlan?.data!,
              fixedAmount: e.target.value,
            },
          })
        }
        disabled={!isEditing}
      />
      {ratePlanType === "Flat Rate Plan" && (
        <>
          <div
            ref={setSurchargeSwitchEl}
            onMouseEnter={() => setShowSurchargeToggle(true)}
            onMouseLeave={() => setShowSurchargeToggle(false)}
          >
            <Switch
              checked={
                (isEditing
                  ? currentEditingRatePlan?.data?.surcharge
                  : ratePlan.data?.surcharge) ?? false
              }
              onChange={() => {
                setSurchargeIsChecked((p) => !p);
                setCurrentEditingRatePlan({
                  ...currentEditingRatePlan!,
                  data: {
                    ...currentEditingRatePlan?.data!,
                    surcharge: !surchargeIsChecked,
                  },
                });
              }}
              disabled={
                !isEditing || !currentEditingRatePlan?.data?.allowedMethod
              }
              label=""
              labelProps={{
                className: "mr-0",
              }}
              textProps={{
                className: "max-sm:hidden",
              }}
            />
            <Popover
              open={showSurchargeToggle && !isMasterSurchargeEnabled}
              placement="bottom"
              referenceEl={surchargeSwitchEl}
            >
              <div className="rounded-lg bg-black p-2 text-white shadow-lg">
                Only applied when master Surcharge Rate is enabled.
              </div>
            </Popover>
          </div>
        </>
      )}
      <div className="flex items-center justify-center gap-x-2">
        <EditControls
          isEditing={isEditing}
          isSaving={isUpdatingRatePlan}
          editButtonProps={{
            variant: "secondary",
            className: "w-full",
            onClick: () => handleEditClick(ratePlan, index),
          }}
          saveButtonProps={{
            disabled: !currentEditingSaveButtonEnabled,
            onClick: () => handleUpdateRatePlan(),
          }}
          cancelButtonProps={{
            onClick: () => setCurrentEditingRatePlan(null),
          }}
        />
      </div>
    </div>
  );
}

export default RatePlanRow;
