import { useEffect, useState } from "react";
import Table from "../../components/Table";
import Error from "../../components/Error";
import { getUserNotesTableColumns } from "./userNotesTableColumns";
import Button from "../../components/Button";
import { PlusIcon } from "@heroicons/react/24/solid";
import useCreateAccountHolderNotesMutation from "../../hooks/data/mutations/useCreateAccountHolderNotesMutation";
import { useSnackbar } from "../../components/Snackbar";
import { useQueryClient } from "@tanstack/react-query";
import Panel from "./Panel";
import useAccountHolderNotesQuery from "../../hooks/data/queries/useAccountHolderNotesQuery";
import { ChangePageEvent } from "../../types";
import { getPaginatorPage } from "../../utils/pagination";
import {
  OnChangeFn,
  SortingState,
  functionalUpdate,
} from "@tanstack/react-table";
import { PageFilter, SortFilter } from "../../atoms";

export type UserNotesTableMeta = {
  onNoteSaved: (text: string) => void;
  onNoteCanceled: () => void;
  isSavingNote: boolean;
};

type Props = {
  accountHolderCode: string;
  timeZoneCode: string;
};

type UserNotesFilter = { accountHolderCode: string } & PageFilter & SortFilter;

function UserNotes({ accountHolderCode, timeZoneCode }: Props): JSX.Element {
  const [filter, setFilter] = useState<UserNotesFilter>({
    accountHolderCode,
    pageNumber: 1,
    pageSize: 20,
    sort: [
      {
        id: "dateCreated",
        desc: true,
      },
    ],
  });

  const [totalPages, setTotalPages] = useState(1);
  const {
    data: accountHolderNotesData,
    isLoading,
    isError,
    error,
  } = useAccountHolderNotesQuery(filter, {
    onSuccess: (data) => setTotalPages(data.totalPages),
  });
  const [tableNotes, setTableNotes] = useState(
    accountHolderNotesData?.pageElements
  );
  const [isAddingNote, setIsAddingNote] = useState(false);

  const { mutate: saveNote, isLoading: isSaving } =
    useCreateAccountHolderNotesMutation();
  const queryClient = useQueryClient();

  const { openSnackbar } = useSnackbar({
    position: "bottom",
    duration: 5000,
  });

  useEffect(() => {
    setTableNotes(accountHolderNotesData?.pageElements);
  }, [accountHolderNotesData?.pageElements]);

  function handleAddNote() {
    setIsAddingNote(true);
    setTableNotes((prevNotes) => [
      {
        userEmail: "",
        dateCreated: "",
        note: "",
      },
      ...(prevNotes || []),
    ]);
  }

  function handleNoteSaved(text: string) {
    saveNote(
      {
        accountHolderCode,
        freeText: text,
      },
      {
        onSuccess() {
          setIsAddingNote(false);
          setTableNotes((prevNotes) =>
            prevNotes?.filter(
              (n) => n.userEmail !== "" && n.dateCreated !== "" && n.note !== ""
            )
          );
          setFilter({
            ...filter,
            pageNumber: 1,
          });
          queryClient.invalidateQueries(["accountHolderNotes", filter]);
          openSnackbar("Note added.", { type: "success" });
        },
        onError() {
          openSnackbar("Failed to add note. Please try again.", {
            type: "error",
          });
        },
      }
    );
  }

  function handleNoteCanceled() {
    setIsAddingNote(false);
    setTableNotes((prevNotes) =>
      prevNotes?.filter(
        (n) => n.userEmail !== "" && n.dateCreated !== "" && n.note !== ""
      )
    );
  }

  function handlePageChange(event: ChangePageEvent, totalPages: number = 1) {
    setFilter({
      ...filter,
      pageNumber: getPaginatorPage(filter.pageNumber, totalPages, event),
    });
  }

  function handlePageSizeChange(pageSize: number) {
    setFilter({
      ...filter,
      pageNumber: 1,
      pageSize,
    });
  }

  function handleSortChange(sort: SortingState) {
    setFilter({
      ...filter,
      sort: functionalUpdate(sort, filter.sort),
    });
  }

  return (
    <Panel className="col-span-12">
      <Panel.Header title="Notes">
        <Button
          custom-id="addNoteButton"
          size="sm"
          onClick={handleAddNote}
          disabled={isLoading || isAddingNote || isError}
          icon={<PlusIcon />}
        >
          Add Note
        </Button>
      </Panel.Header>
      <Panel.Content>
        {error ? (
          <Error message={error} />
        ) : (
          <>
            <div>All notes are in {timeZoneCode} </div>
            <Table
              id="customerNotes"
              data={tableNotes || []}
              columns={getUserNotesTableColumns()}
              sorting={filter.sort}
              setSorting={handleSortChange as OnChangeFn<SortingState>}
              paginatorOptions={{
                currentPage: filter.pageNumber,
                pageSize: filter.pageSize,
                totalPages,
                activeNextPage: accountHolderNotesData?.activeNextPage ?? false,
                activePrevPage: accountHolderNotesData?.activePrevPage ?? false,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
              }}
              isLoading={isLoading || isSaving}
              noRowsText="There are no notes to display."
              meta={{
                onNoteSaved: handleNoteSaved,
                onNoteCanceled: handleNoteCanceled,
                isSavingNote: isSaving,
              }}
              tableClasses="w-auto"
            />
          </>
        )}
      </Panel.Content>
    </Panel>
  );
}

export default UserNotes;
