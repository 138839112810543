import { useEffect, useState } from "react";
import FlatRatePlan from "./FlatRatePlan";
import InterchangePlusPlan from "./InterchangePlusPlan";
import { AccountHolderRatePlan } from "../../services/accountService";
import { useSnackbar } from "../../components/Snackbar";
import useCurrentUserAccount from "../../hooks/data/queries/useCurrentUserAccount";
import Panel from "./Panel";
import Tab from "../../components/Tab";
import ChangePlanConfirmationModal from "./ChangePlanConfirmationModal";

export type RatePlan = {
  id: string;
  label: string;
  data?: AccountHolderRatePlan;
};

// Read Feature Flag (REACT_APP_FF_DISPLAY_INTERCHANGE_PLUS)
const interchangePlusPlan: boolean =
  (process.env.REACT_APP_FF_DISPLAY_INTERCHANGE_PLUS?.toLowerCase() ??
    "true") === "true";

let ratePlanTypes = ["Flat Rate Plan"];
if (interchangePlusPlan) {
  ratePlanTypes.push("Interchange ++ Plan");
}
export type RatePlanType = typeof ratePlanTypes[number];

const countriesThatAllowInterchange = ["US", "PR", "MP", "UM", "VI"];

type Props = {
  accountHolderCode: string;
  accountHolderId: string;
  accountHolderRatePlans: AccountHolderRatePlan[];
  useInterchangeRate: boolean;
  hasMonthlyFee: boolean;
  monthlyFee: string;
  isMasterSurchargeEnabled: boolean;
  inBalancePlatform: boolean;
  country: string;
  isLoading: boolean;
};

function RatePlans({
  accountHolderCode,
  accountHolderId,
  accountHolderRatePlans,
  useInterchangeRate,
  hasMonthlyFee,
  monthlyFee,
  isMasterSurchargeEnabled,
  inBalancePlatform,
  country,
  isLoading,
}: Props): JSX.Element {
  const { data: currentUserAccount } = useCurrentUserAccount();
  const isCSR = currentUserAccount?.type === "CSR";
  const [tabIndex, setTabIndex] = useState(-1);
  const [showConfirmChangeModal, setShowConfirmChangeModal] = useState(false);
  const { openSnackbar } = useSnackbar({
    duration: 30000,
    position: "top",
  });

  useEffect(() => {
    setTabIndex(useInterchangeRate ? 1 : 0);
  }, [useInterchangeRate]);

  function handlePlanChanged(useInterchangeValue: boolean) {
    const newTabIndex = useInterchangeValue ? 1 : 0;
    setTabIndex(newTabIndex);
    setShowConfirmChangeModal(false);
    openSnackbar(`${ratePlanTypes[newTabIndex]} selected.`, {
      type: "success",
      duration: 5000,
    });
  }

  function handlePlanChangeError() {
    setShowConfirmChangeModal(false);
    openSnackbar("Failed to change rate plan type.", {
      type: "error",
      duration: 5000,
    });
  }

  function handleTabChange() {
    if (isCSR && inBalancePlatform) {
      setShowConfirmChangeModal(true);
    }
  }

  return (
    <>
      <Panel className="col-span-5">
        <Panel.Header title="Rate Plans" />
        <Panel.Content>
          {inBalancePlatform &&
          countriesThatAllowInterchange.includes(country) ? (
            <Tab.Group selectedIndex={tabIndex} onChange={handleTabChange}>
              <Tab.List>
                {ratePlanTypes.map((type, i) => (
                  <Tab
                    key={i}
                    index={i}
                    disabled={!isCSR}
                    isLoading={isLoading}
                  >
                    {type}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <FlatRatePlan
                  accountHolderCode={accountHolderCode}
                  accountHolderId={accountHolderId}
                  accountHolderRatePlans={accountHolderRatePlans}
                  useInterchangeRate={useInterchangeRate}
                  ratePlanType="Flat Rate Plan"
                  isMasterSurchargeEnabled={isMasterSurchargeEnabled}
                  isLoading={isLoading}
                />
                {interchangePlusPlan && (
                  <InterchangePlusPlan
                    accountHolderCode={accountHolderCode}
                    accountHolderId={accountHolderId}
                    accountHolderRatePlans={accountHolderRatePlans}
                    useInterchangeRate={useInterchangeRate}
                    isLoading={isLoading}
                    hasMonthlyFee={hasMonthlyFee}
                    ratePlanType="Interchange ++ Plan"
                    monthlyFee={monthlyFee}
                  />
                )}
              </Tab.Panels>
            </Tab.Group>
          ) : (
            <div className="flex flex-col">
              <h3 className="font-semibold">Flat Rate Plan</h3>
              <FlatRatePlan
                accountHolderCode={accountHolderCode}
                accountHolderId={accountHolderId}
                accountHolderRatePlans={accountHolderRatePlans}
                useInterchangeRate={useInterchangeRate}
                ratePlanType="Flat Rate Plan"
                isMasterSurchargeEnabled={isMasterSurchargeEnabled}
                isLoading={isLoading}
              />
            </div>
          )}
        </Panel.Content>
      </Panel>
      <ChangePlanConfirmationModal
        isOpen={showConfirmChangeModal}
        accountHolderCode={accountHolderCode}
        useInterchangeRate={tabIndex === 1}
        onPlanChanged={handlePlanChanged}
        onError={handlePlanChangeError}
        onClose={() => setShowConfirmChangeModal(false)}
      />
    </>
  );
}

export default RatePlans;
