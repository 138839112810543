import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AccountHolder,
  AccountHolderRatePlan,
  updateRatePlan,
  UpdateRatePlanBody,
} from "../../../services/accountService";
import { formatAmount } from "../../../utils/currency";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";

function getRatePlanChanges(
  previous: AccountHolderRatePlan,
  current: AccountHolderRatePlan
): string {
  const changes: string[] = [];

  if (previous.fixedAmount !== current.fixedAmount) {
    changes.push(
      `Fixed (${formatAmount(+previous.fixedAmount)} to ${formatAmount(
        +current.fixedAmount
      )})`
    );
  }

  if (previous.percentageValue !== current.percentageValue) {
    changes.push(
      `Percentage (${formatAmount(+previous.percentageValue)} to ${formatAmount(
        +current.percentageValue
      )})`
    );
  }

  if (previous.allowedMethod !== current.allowedMethod) {
    changes.push(
      `Allowed (${previous.allowedMethod ? "yes" : "no"} to ${
        current.allowedMethod ? "yes" : "no"
      })`
    );
  }

  if (previous.surcharge !== current.surcharge) {
    changes.push(
      `Surcharge (${previous.surcharge ? "yes" : "no"} to ${
        current.surcharge ? "yes" : "no"
      })`
    );
  }

  return `Field rate plan ${previous.paymentMethodType.toUpperCase()} changed: ${changes.join(
    ", "
  )}`;
}

export default function useUpdateRatePlanMutation(accountHolderCode: string) {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<string, Error, UpdateRatePlanBody>(
    (body) => updateRatePlan(body),
    {
      async onSuccess(_, updatedRatePlan) {
        const accountConfigData = queryClient.getQueryData<AccountHolder>([
          "accountConfig",
          accountHolderCode,
        ]);

        const updatedRatePlans = accountConfigData?.ratePlans.some(
          (ratePlan) =>
            ratePlan.paymentMethodType === updatedRatePlan.paymentMethodType
        )
          ? accountConfigData?.ratePlans.map((ratePlan) => {
              return ratePlan.paymentMethodType ===
                updatedRatePlan.paymentMethodType
                ? updatedRatePlan
                : ratePlan;
            })
          : [...(accountConfigData?.ratePlans || []), updatedRatePlan];

        queryClient.setQueryData(["accountConfig", accountHolderCode], {
          ...accountConfigData,
          ratePlans: updatedRatePlans,
        });

        const previousUpdatedRatePlan = accountConfigData?.ratePlans?.find(
          (rp) => rp.paymentMethodType === updatedRatePlan.paymentMethodType
        );

        if (previousUpdatedRatePlan && updatedRatePlan) {
          await createNote({
            accountHolderCode,
            freeText: getRatePlanChanges(
              previousUpdatedRatePlan,
              updatedRatePlan
            ),
          });
        }

        queryClient.invalidateQueries(["accountConfig", accountHolderCode]);
      },
    }
  );
}
