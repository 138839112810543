import { atom } from "jotai";
import {
  DateFilterType,
  TransactionStatusType,
  TransactionType,
} from "../services/transactionsService";
import { SortingState } from "@tanstack/react-table";
import { atomWithHash } from "jotai-location";
import { identity } from "../utils/fn";
import { DateTime } from "luxon";
import { AccountHolder, FilterStatusType } from "../services/accountService";
import { getDateTimeFromDateFilterType } from "../utils/dateTime";
import { urlDateFormat } from "../utils/dateTime";
import { getDefaultTransactionsFilter } from "../utils/transaction";
import { getDefaultOnboardingFilter } from "../utils/accounts";
import { UserAccount } from "../utils/userAccounts";

const pageNumberAtom = atomWithHash("pageNumber", 1);
const pageSizeAtom = atomWithHash("pageSize", 20);

function sortingStateToString(val: SortingState) {
  return val.reduce((acc, current) => {
    const currentToString = `${current.id}:${current.desc ? "desc" : "asc"}`;
    return acc ? `${acc},${currentToString}` : currentToString;
  }, "");
}

function stringToSortingState(val: string) {
  return val
    ? val.split(",").map((x) => {
        const [id, type] = x.split(":");
        return {
          id,
          desc: type === "desc",
        };
      })
    : [];
}

const { sort: defaultOnboardingSort } = getDefaultOnboardingFilter();

const sortAtom = atomWithHash<SortingState>("sort", defaultOnboardingSort, {
  serialize: sortingStateToString,
  deserialize: stringToSortingState,
});

export type PageFilter = {
  pageNumber: number;
  pageSize: number;
};

export type SortFilter = {
  sort: SortingState;
};

export type AccountHoldersFilter = {
  accountHolderCode: string;
  status: FilterStatusType;
  showClosed: boolean;
} & PageFilter &
  SortFilter;

const accountHolderCodeAtom = atomWithHash("accountHolderCode", "", {
  serialize: identity,
  deserialize: identity,
});
const statusAtom = atomWithHash<FilterStatusType>("status", "inprogress", {
  serialize: identity,
  deserialize: identity,
});
const showClosedAtom = atomWithHash<boolean>("showClosed", false);

export const accountHoldersFilterAtom = atom<
  AccountHoldersFilter,
  [AccountHoldersFilter],
  void
>(
  (get) => ({
    accountHolderCode: get(accountHolderCodeAtom),
    status: get(statusAtom),
    showClosed: get(showClosedAtom),
    pageNumber: get(pageNumberAtom),
    pageSize: get(pageSizeAtom),
    sort: get(sortAtom),
  }),
  (get, set, updated) => {
    set(accountHolderCodeAtom, updated.accountHolderCode);
    set(statusAtom, updated.status);
    set(showClosedAtom, updated.showClosed);
    set(pageNumberAtom, updated.pageNumber);
    set(pageSizeAtom, updated.pageSize);
    set(sortAtom, updated.sort);
  }
);

export type AmountFilter = {
  from: number | null;
  to: number | null;
};

export type DateFilter = {
  type: DateFilterType;
  from: DateTime | null;
  to: DateTime | null;
};

export type TransactionsFilter = {
  amount: AmountFilter;
  cardOrAccountNumber: string;
  pspReference: string;
  merchantReference: string;
  date: DateFilter;
  transactionType: TransactionType | null;
  status: TransactionStatusType[];
  accountHolderCode?: string;
  hideZeroUsdTransactions: boolean;
} & PageFilter &
  SortFilter;

const hideZeroUsdTransactionsAtom = atomWithHash<boolean>(
  "hideZeroUsdTransactions",
  true
);

const amountAtom = atomWithHash<AmountFilter>(
  "amount",
  {
    from: null,
    to: null,
  },
  {
    serialize: (val) => `${val.from},${val.to}`,
    deserialize: (val) => {
      const [fromVal, toVal] = val.split(",");
      return {
        from: isNaN(Number(fromVal)) ? null : Number(fromVal),
        to: isNaN(Number(toVal)) ? null : Number(toVal),
      };
    },
  }
);
const pspReferenceAtom = atomWithHash("pspReference", "", {
  serialize: identity,
  deserialize: identity,
});
const cardOrAccountNumberAtom = atomWithHash("cardOrAccountNumber", "", {
  serialize: identity,
  deserialize: identity,
});
const merchantReferenceAtom = atomWithHash("merchantReference", "", {
  serialize: identity,
  deserialize: identity,
});

const dateAtom = atomWithHash<DateFilter>(
  "date",
  {
    type: "Custom",
    from: null,
    to: null,
  },
  {
    serialize: ({ type, from, to }) => {
      if (type === "Custom")
        return [
          type,
          from ? from.toFormat(urlDateFormat) : "",
          to ? to.toFormat(urlDateFormat) : "",
        ].join(",");
      return `${type},,`;
    },
    deserialize: (val) => {
      const [type, from, to] = val.split(",");

      const dateType = type as DateFilterType;
      const fromDateTime = from
        ? DateTime.fromFormat(from, urlDateFormat)
        : getDateTimeFromDateFilterType(dateType);
      const toDateTime = to
        ? DateTime.fromFormat(to, urlDateFormat)
        : DateTime.now();

      return {
        type: dateType,
        from: fromDateTime,
        to: toDateTime,
      };
    },
  }
);

const transactionTypeAtom = atomWithHash<TransactionType | null>(
  "transactionType",
  null,
  {
    serialize: (val) => val || "",
    deserialize: (val) => (val as TransactionType) || null,
  }
);

const transactionStatusAtom = atomWithHash<TransactionStatusType[]>(
  "status",
  [],
  {
    serialize: (val) => val.join(","),
    deserialize: (val) =>
      val ? (val.split(",") as TransactionStatusType[]) : [],
  }
);

const { sort: defaultTransactionsSort } =
  getDefaultTransactionsFilter("Last24Hours");

const transactionsSortAtom = atomWithHash<SortingState>(
  "sort",
  defaultTransactionsSort,
  {
    serialize: sortingStateToString,
    deserialize: stringToSortingState,
  }
);

export const transactionsFilterAtom = atom<
  TransactionsFilter,
  [TransactionsFilter],
  void
>(
  (get) => ({
    accountHolderCode: get(accountHolderCodeAtom),
    amount: get(amountAtom),
    pspReference: get(pspReferenceAtom),
    cardOrAccountNumber: get(cardOrAccountNumberAtom),
    merchantReference: get(merchantReferenceAtom),
    date: get(dateAtom),
    transactionType: get(transactionTypeAtom),
    status: get(transactionStatusAtom),
    pageNumber: get(pageNumberAtom),
    pageSize: get(pageSizeAtom),
    sort: get(transactionsSortAtom),
    hideZeroUsdTransactions: get(hideZeroUsdTransactionsAtom),
  }),
  (get, set, updated) => {
    set(accountHolderCodeAtom, updated.accountHolderCode);
    set(amountAtom, updated.amount);
    set(pspReferenceAtom, updated.pspReference);
    set(cardOrAccountNumberAtom, updated.cardOrAccountNumber);
    set(merchantReferenceAtom, updated.merchantReference);
    set(dateAtom, updated.date);
    set(transactionTypeAtom, updated.transactionType);
    set(transactionStatusAtom, updated.status);
    set(pageNumberAtom, updated.pageNumber);
    set(pageSizeAtom, updated.pageSize);
    set(transactionsSortAtom, updated.sort);
    set(hideZeroUsdTransactionsAtom, updated.hideZeroUsdTransactions);
  }
);

export const currentUserAccountAtom = atom<UserAccount | null>(null);
export const accountConfigAtom = atom<AccountHolder | null>(null);
