import { SortingState } from "@tanstack/react-table";
import { AccountHoldersFilter } from "../atoms";
import {
  AccountHolder,
  MerchantAccount,
  OnboardingStatus,
} from "../services/accountService";

export function getEmptyAccountHolder(): AccountHolder {
  return {
    edition: "",
    jrId: "",
    adyenAccountHolderCode: "",
    legalEntityType: "",
    companyName: "",
    companyEmail: "",
    contactFirstName: "",
    contactLastName: "",
    country: "",
    adyenStatus: "",
    jrPayStatus: "",
    legalBusinessName: "",
    id: "",
    contactFullName: "",
    onboardingStatus: "In Progress",
    ratePlans: [],
    paymentProcessingState: false,
    payoutState: false,
    adyenMerchantAccount: "",
    adyenPayoutSchedule: {},
    accounts: [],
    shopperStatement: "",
    convFee: "",
    convFeeRate: "",
    techFeeRate: "",
    allowedPaymentMethods: [],
    merchantStatus: "",
    adyenCaptureDelay: "",
    accountHolderNotes: [],
    dailySubscription: null,
    monthlySubscription: null,
    useInterchangeRate: null,
    timeZoneCode: "",
    timeZone: {
      code: "",
      country: "",
      name: "",
    },
    useTransAmountLimit: false,
    transAmountLimit: "",
    useDailyTransAmountLimit: false,
    dailyTransAmountLimit: "",
    useTechFee: false,
    useConvFee: false,
    legalEntityId: "",
    merchantAccountId: "",
    inBalancePlatform: false,
    balancePlatformAccountHolderId: "",
    migrated: false,
    migrationDate: "",
    hasMonthlyFee: false,
    monthlyFee: "",
    stores: [],
    verificationErrors: "",
    dateCreated: "",
    achDailyTransAmountLimit: "",
    achTransAmountLimit: "",
    useACHDailyTransAmountLimit: false,
    useACHTransAmountLimit: false,
  };
}

export function getEmptyMerchantAccount(): MerchantAccount {
  return {
    merchantAccountId: "",
    merchantAccountName: "",
    merchantAccountType: "",
    merchantCurrency: "",
    processingFeeAccount: "",
    techFeeAccount: "",
    balancePlatformId: "",
    balancePlatformProcFeeAccount: "",
    balancePlatformTechFeeAcount: "",
    themeId: "",
    merchantCountryCode: "",
    defaultForCountryCode: false,
  };
}

export function getOnboardingStatus(
  adyenStatus: string,
  jrPayStatus: string,
  migrated: boolean
): OnboardingStatus {
  if (adyenStatus === "Active" && jrPayStatus === "True") {
    return "Complete";
  } else if (adyenStatus === "Active" && jrPayStatus !== "True") {
    return migrated ? "KYC Needed" : "In Progress";
  }
  return "In Progress";
}

export function getDefaultOnboardingFilter(): AccountHoldersFilter {
  return {
    accountHolderCode: "",
    status: "complete",
    showClosed: false,
    pageNumber: 1,
    pageSize: 100,
    sort: [{ id: "dateCreated", desc: true }],
  };
}

function getOnboardingFilterSearchParams(filter: AccountHoldersFilter) {
  return Object.entries(filter).reduce((acc, [key, value], i) => {
    let formattedValue: string;
    switch (key) {
      case "sort":
        const defaultSort = value as SortingState;
        formattedValue = `${defaultSort.at(0)?.id}:${
          defaultSort.at(0)?.desc ? "desc" : "asc"
        }`;
        break;
      default:
        formattedValue = value as string;
    }
    return (
      acc + `${i > 0 ? "&" : ""}${key}=${encodeURIComponent(formattedValue)}`
    );
  }, "");
}

export function getOnboardingPageDefaultUrl() {
  return (
    "onboarding#" +
    getOnboardingFilterSearchParams(getDefaultOnboardingFilter())
  );
}
