import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import useAccountConfigQuery from "../hooks/data/queries/useAccountConfigQuery";
import LoadingFullScreen from "./LoadingFullScreen";
import useCurrentUserAccount from "../hooks/data/queries/useCurrentUserAccount";
import Layout from "./Layout";
import ErrorFullScreen from "./ErrorFullScreen";
import { useEffect } from "react";
import { useSnackbar } from "./Snackbar";
import { accountConfigAtom, currentUserAccountAtom } from "../atoms";
import { useAtom } from "jotai";

function Main(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearSnackbars } = useSnackbar();
  const { inProgress } = useMsal();
  const [_, setCurrentUserAccount] = useAtom(currentUserAccountAtom);
  const [__, setAccountConfig] = useAtom(accountConfigAtom);
  const {
    data: currentUserAccount,
    isLoading: isLoadingCurrentUserAccount,
    isError: isCurrentUserAccountError,
  } = useCurrentUserAccount({
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess(data) {
      setCurrentUserAccount(data);
    },
  });
  const isComingFromWelcome =
    location.state?.previousLocationPathName?.includes("welcome");
  const {
    isLoading: isLoadingAccountConfig,
    isFetching: isFetchingAccountConfig,
    isError: isAccountConfigError,
  } = useAccountConfigQuery(currentUserAccount?.accountHolderCode, {
    enabled: currentUserAccount?.type === "Customer",
    onSuccess: async (data) => {
      setAccountConfig(data);
      if (
        data.onboardingStatus === "In Progress" ||
        data.onboardingStatus === "KYC Needed"
      ) {
        navigate("/welcome");
        return;
      }

      if (data.merchantStatus === "Closed") {
        navigate("/disabledUser");
      }
    },
  });

  useEffect(() => {
    clearSnackbars();
  }, [location]);

  const showLoadingScreen =
    inProgress !== InteractionStatus.None ||
    isLoadingCurrentUserAccount ||
    (currentUserAccount?.type === "Customer" &&
      (isComingFromWelcome ? isFetchingAccountConfig : isLoadingAccountConfig));

  function getContent() {
    if (showLoadingScreen) {
      return <LoadingFullScreen />;
    }

    if (isCurrentUserAccountError || isAccountConfigError) {
      return <ErrorFullScreen />;
    }

    return <Layout />;
  }

  return <div className="relative">{getContent()}</div>;
}

export default Main;
